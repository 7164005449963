<template>
  <section id="dashboard-analytics">
    <b-col class="content-header-left mb-1" cols="12" md="12">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">Master</h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb style="font-size: 0.9rem">
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="14"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item active> MASTER </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-row class="match-height">
        <b-col md="3" cols="6" xl="2" v-for="data in modules">
          <statistic-card-vertical
            :icon="data.icon"
            :statistic="data.title"
            :color="data.color"
            :route="data.route"
          />
        </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol, BButton,

  BBreadcrumb,
  BBreadcrumbItem,} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'


export default {
  components: {
    BButton,
    StatisticCardVertical,
    BRow,
    BCol,

  BBreadcrumb,
  BBreadcrumbItem,

    // InvoiceList,
  },
  data () {
    return {
      data: [
        {
          icon:"UsersIcon",
          title:"HR",
          color:'info',
          route:"/master/hr"
        },
        {
          icon:"EditIcon",
          title:"Account",
          color:'success',

          route:"/master/accounting"
        },
        {
          icon:'PhoneIcon',
          title:'CRM',
          color:'danger',

          route:'/master/crm'
        }
      ],
      userData: localStorage.getItem('userData'),
      userRole: '',
      sidebarData:'',
      modules:''
    }
  },
  created () {
    // data
    // this.$http.get('/analytics/data').then((response) => {
    //   this.data = response.data
    // })
  },
  mounted () {
    this.userRole = JSON.parse(this.userData).role
    this.sidebarData = JSON.parse(localStorage.getItem('sidebarData'))
    this.setRoute()
  },
  methods: {
    // kFormatter
    setRoute () {

      this.sidebarData.map((item) => {
        if (item.title == 'Masters') {
          this.modules = item.children
        }
      })
      this.modules.map((item) => {
        this.data.map((inner) => {
          if (inner.title == item.title) {
            item.route = inner.route
            item.color = inner.color

          }
        })
      })
    }
  }
}
</script>
